// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ENVIRONMENT_URLS } from './environment-urls.const';

export const environment = {
  ...{
    sentryio: 'https://c2abce29a05e46cfa1876be6be4db808@o309582.ingest.sentry.io/1774847',
    production: true,
    hjSiteId: 3560137,
    gtmId: 'G-2MESK58MPD',
    isGoogleSignInEnabled: false,
    ipInfoAuthKey: '16ed214da3379a',
    googleClientId: '627045243124-fddnk56nln3hmfpb9p1elrsmc4vss7mt.apps.googleusercontent.com',
    companyEnrichmentKey: '6deb315f71dc48c79205b349912aa15d',
    abstractApiAuthKey: '811c7d409a4b44459c7e9d4f974bff62',
    domain: 'https://www.movemeback.com/api/v2',
    linkedIn: {
      clientId: '783puyfc5f7zfm',
      cs: 'cyDmj8FdY54JqP3U',
      scope: 'r_liteprofile%20r_emailaddress'
    },
    recaptchaSiteKey: '6Ld5sqAqAAAAAOLvejHDVg_8ulDV5KRkUsaMmvoZ',
    mailchimp: {
      subscribe: 'https://movemeback.us8.list-manage.com/subscribe/post-json?u=205ee7393087554d205cec0cd&id=0ae9dfc0fe'
  },
  },
  ...{ api: ENVIRONMENT_URLS.api }
}
